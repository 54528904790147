import * as React from 'react';
import {FC} from 'react';
import {Alert} from 'antd';
import {useFlags} from 'launchdarkly-react-client-sdk';

export const OutageAlert: FC = () => {
  const {cloudOutageAlert} = useFlags();

  return (
    <>
      {cloudOutageAlert && (
        <Alert
          message={
            <div id='outage-alert'>
              Some Cribl.Cloud services are experiencing issues that might affect the availability of your Cloud
              environment. Please visit{' '}
              <a href='https://status.cribl.cloud' target='_blank' rel='noreferrer'>
                status.cribl.cloud
              </a>{' '}
              for more details, and join Cribl Community Slack's #logstream-cloud channel for updates.
            </div>
          }
          type='warning'
          closable
          banner
        />
      )}
    </>
  );
};
