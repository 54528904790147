import {SafeLocalStorage} from './LocalStorage';

export const getInviteInfo = ({isNew} = {isNew: false}) => {
  if (!isNew) {
    return JSON.parse(SafeLocalStorage.getItem('invitation') || '{}');
  }
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const tenantId = searchParams.get('tenantId');
  const screen_hint = searchParams.get('screen_hint');
  const login_hint = searchParams.get('login_hint');
  const info = {code, tenantId, screen_hint, login_hint, shouldAcceptInvite: Boolean(code && tenantId)};
  SafeLocalStorage.setItem('invitation', JSON.stringify(info));
  return info;
};

export const resetInviteInfo = () => SafeLocalStorage.removeItem('invitation');
