import * as React from 'react';
import {message} from 'antd';
// @ts-ignore
import VerificationForm from './VerificationForm';
import {navigate} from 'gatsby';
import {MainLayout} from '../Layout/MainLayout';

export default class Verification extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      sources: [],
      destinations: [],
      name: props?.location?.state?.name,
      emailAddress: props?.location?.state?.emailAddress,
      password: props?.location?.state?.password,
      code: ''
    };
  }

  public render() {
    return (
      <MainLayout className={'verification'}>
        <VerificationForm
          onChangeEvent={this.setStateEvent.bind(this)}
          emailAddress={this.state.emailAddress}
          signup={this.verify.bind(this)}
          parentState={this.state}
        />
      </MainLayout>
    );
  }

  async verify() {
    const {emailAddress, password, code} = this.state;
    const url = `${process.env.GATSBY_API_URL}/api/verify`;
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({emailAddress, code})
    });
    const response = await rawResponse.json();

    if (response.code) {
      message.error(response.message);
    } else {
      navigate('/loading', {
        state: {
          emailAddress,
          password
        }
      });
    }
  }

  private setStateEvent(key, value) {
    const obj = {};
    obj[key] = value;
    this.setState(obj);
  }
}
