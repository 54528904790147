import * as React from 'react';
import cx from 'classnames';
import {isBrowser, isMobile} from 'react-device-detect';
import {Button, Card, Col, Form, Input} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
// @ts-ignore
import CriblIcon from '../../static/svgs/criblCloud.white.svg';
import '../../styles/verification.less';
import {getInviteInfo} from '../../utils/InviteUtils';

const layout = {};

interface IVerficationFormState {
  tosAccepted: boolean;
}

export interface IVerficationFormProps {
  error?: any;
  onChangeEvent: (key: any, value: any) => void;
  parentState: any;
  emailAddress: string;
  signup: (step) => void;
}

export default class VerificationForm extends React.Component<IVerficationFormProps, IVerficationFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tosAccepted: false
    };
  }

  onInputChange(key: any, e: any) {
    this.props.onChangeEvent(key, e.target.value);
  }

  render() {
    const {shouldAcceptInvite} = getInviteInfo();
    return (
      <>
        <Card className={cx({'main-card': isBrowser, 'main-card-mobile': isMobile})}>
          <div style={{textAlign: 'center', marginTop: '5em', marginBottom: '1em'}}>
            <CriblIcon />
          </div>
          <Col span={24}>
            <Paragraph style={{textAlign: 'center', marginTop: '3em', color: '#fff'}}>
              A verification code has been sent to {this.props.emailAddress}
            </Paragraph>
          </Col>
          <Col span={24} className={'verificationHeader'}>
            <h1 style={{color: '#fff'}}>Enter your verification code</h1>
          </Col>
          <Col span={24} className={'verificationHeader'}>
            <h5 style={{color: '#fff'}}>You're almost there!</h5>
          </Col>
          <Form
            {...layout}
            className={'main-form-section'}
            name='basic'
            initialValues={{remember: true, fullName: this.props.parentState.name}}
          >
            <Col span={16} offset={4} style={{textAlign: 'center'}}>
              <Form.Item name='email' rules={[{required: true, message: 'Enter verification code'}]}>
                <Input
                  size={'large'}
                  style={{textAlign: 'center'}}
                  onChange={(e) => this.onInputChange('code', e)}
                  maxLength={6}
                  placeholder={'Code'}
                />
              </Form.Item>
            </Col>
            <Col span={16} offset={4} style={{textAlign: 'center'}}>
              <Button
                onClick={() => this.props.signup(1)}
                type='primary'
                htmlType='submit'
                block={true}
                className={'orange-button'}
              >
                Verify{shouldAcceptInvite ? ' and accept your invite' : ''}
              </Button>
            </Col>
            <Col span={24} className={`error ${this.props.error ? 'has-error' : ''}`}>
              <Col span={14} offset={7}>
                {this.props.error} <ExclamationCircleOutlined />
              </Col>
            </Col>
          </Form>
        </Card>
      </>
    );
  }
}
