import * as React from 'react';
import {FC} from 'react';
import {SideComponent} from '../SideComponent/SideComponent';
import {OutageAlert} from '../Alert/OutageAlert';
import {Layout} from 'antd';
import {BrowserView, MobileView} from 'react-device-detect';
const {Content} = Layout;
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';

type MainLayoutProps = {
  children: React.ReactNode;
  className?: string;
  hideSideComponent?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = (props) => {
  const {children, className, hideSideComponent} = props;
  return (
    <>
      <OutageAlert />
      <Layout className={className}>
        {!hideSideComponent && (
          <BrowserView renderWithFragment={true}>
            <SideComponent />
          </BrowserView>
        )}
        <Content className={sharedStyles.layoutContent}>{children}</Content>
        {!hideSideComponent && (
          <MobileView renderWithFragment={true}>
            <SideComponent />
          </MobileView>
        )}
      </Layout>
    </>
  );
};
