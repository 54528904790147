// extracted by mini-css-extract-plugin
export var container = "shared-module--container--WOZ7g";
export var criblLogo = "shared-module--criblLogo--WKTtf";
export var iconContainer = "shared-module--iconContainer--ylxm0";
export var iconContainerAuth0 = "shared-module--iconContainerAuth0--j4Tsc";
export var layoutContent = "shared-module--layoutContent--9FjUi";
export var rightMenu = "shared-module--rightMenu--jZybe";
export var subMenu = "shared-module--subMenu--j1blD";
export var topLink = "shared-module--topLink--O8wUs";
export var topNavEdge = "shared-module--topNavEdge--J+QVM";
export var topSection = "shared-module--topSection--P+lnw";
export var topSectionAuth0 = "shared-module--topSectionAuth0--idt6H";