class LocalStorage implements Storage {
  private storage: Storage;

  constructor() {
    //check if localStorage is available
    try {
      this.storage = window.localStorage;
    } catch (e) {
      console.warn('localStorage not available, swiching to in memory storage');
      this.storage = new MemoryStorage();
    }
  }

  public get length(): number {
    return this.storage.length;
  }

  public clear(): void {
    this.storage.clear();
  }

  public key(index: number): string | null {
    return this.storage.key(index);
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    try {
      this.storage.setItem(key, value);
    } catch (e) {
      //setItem may throw a "QuotaExceededError" so switch to MemoryStorage and copy the existing values
      if (!(this.storage instanceof MemoryStorage)) {
        console.warn('unable to store data in localStorage, swiching to in memory storage');
        this.storage = new MemoryStorage(this.storage);
      } else {
        throw e;
      }
    }
  }

  public getNumber(key: string, defaultValue = 0) {
    const val = this.getItem(key) || defaultValue;
    const numVal = Number(val);
    return isNaN(numVal) ? defaultValue : numVal;
  }

  public getString(key: string, defaultValue?: string) {
    return this.getItem(key) || defaultValue;
  }

  public getBoolean(key: string, defaultValue = false) {
    const val = this.getItem(key);
    return val ? val === 'true' : defaultValue;
  }

  public getObject<T = any>(key: string, defaultValue: any = {}): T {
    const val = this.getItem(key) || `'${defaultValue}'`;
    try {
      return JSON.parse(val);
    } catch (ignore) {
      return defaultValue;
    }
  }

  public set(key: string, val: any) {
    const strVal = typeof val === 'string' ? val.toString() : JSON.stringify(val);
    this.setItem(key, strVal);
  }

  public remove(key: string) {
    this.removeItem(key);
  }
}

class MemoryStorage implements Storage {
  private storage = new Map<string, string>();

  constructor(storageToCopyFrom?: Storage) {
    if (storageToCopyFrom) {
      for (let i = 0; i < storageToCopyFrom.length; i++) {
        const key = storageToCopyFrom.key(i);
        if (key) {
          this.setItem(key, storageToCopyFrom.getItem(key) as string);
        }
      }
    }
  }

  public get length(): number {
    return this.storage.size;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): string | null {
    return this.storage.get(key) || null;
  }

  public key(index: number): string | null {
    return Array.from(this.storage.keys())[index] || null;
  }

  public removeItem(key: string): void {
    this.storage.delete(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }
}

export const SafeLocalStorage = new LocalStorage();
